/**
 * WordPress needs a little help to understand what is going on with jQuery
 */

(function ($, root, undefined) {
	$(() => {
		/**
		 * DOM ready, take it away jQuery
		 */

		/**
		 * Create an accordion for the FAQ page
		 * In this rewritten code, we first check if the body element has the "page-template-template-faq" class using classList.contains. If it does, we get all the accordion buttons using getElementsByClassName. We then loop through each accordion button and add a click event listener to it using addEventListener. Inside the event listener, we toggle the "active" class on the clicked button using classList.toggle. We also toggle the "show" class on the next sibling element of the clicked button (which is assumed to be the panel) using nextElementSibling and classList.toggle. Finally, we check if the panel is open or not by checking its style.display property, and we open or close it accordingly by setting its style.display property to either "block" or "none".
		 */

		if (document.body.classList.contains("page-template-template-faq")) {
			// Get all the accordion buttons
			const accordions = document.getElementsByClassName("accordion");

			// Loop through all accordion buttons
			for (let i = 0; i < accordions.length; i++) {
				const accordion = accordions[i];

				// Add click event listener to each accordion button
				accordion.addEventListener("click", function () {
					// Toggle the active class on the button
					this.classList.toggle("active");

					// Toggle the show class on the panel
					const panel = this.nextElementSibling;

					// If the panel is open, close it. Otherwise, open it.
					if (panel.style.display === "block") {
						panel.style.display = "none";
					} else {
						panel.style.display = "block";
					}
				});
			}
		}

		/**
		 * initMap
		 *
		 * Renders a Google Map onto the selected jQuery element
		 *
		 * @date    22/10/19
		 * @since   5.8.6
		 *
		 * @param   jQuery $el The jQuery element.
		 * @return  object The map instance.
		 */
		function initMap($el) {
			// Find marker elements within map.
			var $markers = $el.find(".marker");

			// Create gerenic map.
			var mapArgs = {
				zoom: $el.data("zoom") || 17,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
			};
			var map = new google.maps.Map($el[0], mapArgs);

			// Add markers.
			map.markers = [];
			$markers.each(function () {
				initMarker($(this), map);
			});

			// Center map based on markers.
			centerMap(map);

			// Return map instance.
			return map;
		}

		/**
		 * initMarker
		 *
		 * Creates a marker for the given jQuery element and map.
		 *
		 * @date    22/10/19
		 * @since   5.8.6
		 *
		 * @param   jQuery $el The jQuery element.
		 * @param   object The map instance.
		 * @return  object The marker instance.
		 */
		function initMarker($marker, map) {
			// Get position from marker.
			var lat = $marker.data("lat");
			var lng = $marker.data("lng");
			var latLng = {
				lat: parseFloat(lat),
				lng: parseFloat(lng),
			};

			// Create marker instance.
			var marker = new google.maps.Marker({
				position: latLng,
				map: map,
			});

			// Append to reference for later use.
			map.markers.push(marker);

			// If marker contains HTML, add it to an infoWindow.
			if ($marker.html()) {
				// Create info window.
				var infowindow = new google.maps.InfoWindow({
					content: $marker.html(),
				});

				// Show info window when marker is clicked.
				google.maps.event.addListener(marker, "click", function () {
					infowindow.open(map, marker);
				});
			}
		}

		/**
		 * centerMap
		 *
		 * Centers the map showing all markers in view.
		 *
		 * @date    22/10/19
		 * @since   5.8.6
		 *
		 * @param   object The map instance.
		 * @return  void
		 */
		function centerMap(map) {
			// Create map boundaries from all map markers.
			var bounds = new google.maps.LatLngBounds();
			map.markers.forEach(function (marker) {
				bounds.extend({
					lat: marker.position.lat(),
					lng: marker.position.lng(),
				});
			});

			// Case: Single marker.
			if (map.markers.length == 1) {
				map.setCenter(bounds.getCenter());

				// Case: Multiple markers.
			} else {
				map.fitBounds(bounds);
			}
		}

		// Render maps on page load.
		$(".acf-map").each(function () {
			var map = initMap($(this));
		});
	});
})(jQuery, this);
